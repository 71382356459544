import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints, Components } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.FORM_SILVERPOP;

export const StyledFormSilverpop = styled.div`
  max-width: 50%;
  width: 100%;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};

  h1, h2, h3, h4, h5, h6 {
    ${({ theme }) => theme.fonts.SEC_HDR_BLK};
    margin-bottom: 5px;
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }

  h1 {
    font-size: ${({ theme }) => theme.FontSize.MED30};
  }

  h2 {
    font-size: ${({ theme }) => theme.FontSize.MED10};
  }

  h3 {
    font-size: ${({ theme }) => theme.FontSize.REG40};
  }

  h4 {
    font-size: ${({ theme }) => theme.FontSize.REG40};
  }

  h5 {
    font-size: ${({ theme }) => theme.FontSize.REG30};
  }

  h6 {
    font-size: ${({ theme }) => theme.FontSize.REG30};
  }

  p, label, strong {
    font-size: ${({ theme }) => theme.FontSize.REG30};
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    margin-bottom: 5px;
  }


  ${Breakpoints.smallest(css`
    max-width: 100%;
  `)}


  table {
    width: 100%;
    margin-bottom: 15px;
  }

  input[type="checkbox"]{
    transform: scale(1.5);
    padding: 10px;
  }

  .textInput {
    margin-bottom: 15px;
  }

  .checkbox_wrap {
    display: flex;
    align-items: baseline;
    padding-top: 15px;
  }

  .checkbox_label {
    margin-left: 10px;
    font-size: 14px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 1.25;
    color: #55595c;
    background-color: white;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    margin-bottom: 15px;
  }

  .fieldLabel, .sectionHeader {
    ${({ theme }) => theme.fonts.SEC_HDR_BLK};
    font-size: 16px;
    padding-bottom: 10px;
  }

  .form-button {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    text-transform: uppercase;
    border: none;
    padding: 15px 20px;
    font-size: 18px;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }
`;
